@import "normalize";

html {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

body {
  font-family: 'Mulish', 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-top: 0;
}

button {
  outline: 0;
}

a {
  text-decoration: none;
}

.simplebar-scrollbar::before {
  background-color: #D9D9D9;
}

.simplebar-scrollbar.simplebar-visible::before  {
  background-color: #D9D9D9;
  opacity: 1;
}

/* scrollbar */
/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}
/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 16px;
  border: 4px solid #fff;
}
/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display:none;
}